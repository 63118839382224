import React from "react";
import InitialForm from "../InitialForm";
import { FormProvider, useForm } from "react-hook-form";
import useSelfieDialog from "../useSelfieDialog";
import NiceModal from "@ebay/nice-modal-react";
import WizardCheckListModal from "../WizardCheckListModal";
import UploadPhotosModal from "../UploadPhotosModal";
import { useNavigate, useParams } from "react-router-dom";
import useRoomInspectionWizardDialog from "../useRoomInspectionWizardDialog";
import CheckInCompleteModal from "../CheckInCompleteModal";
import { IApiResponse } from "../../../types/IApiResponse";
import { EXECUTE_REQUEST_PATH } from "../../../constants";
import { API } from "../../../services/API";
import SignWellWindow from "../../../../../components/SignWellWindow";
import { URLS } from "../../../../../_config";
import useCurrentUser from "../../../hooks/useCurrentUser";
import AsyncContent from "../../../components/AsyncBox";
import { showErrorAlert } from "../../../components/Notify";

function CheckInFlow() {
  const methods = useForm();
  const isInitialCheckIn = true;
  const [isLoading, setIsLoading] = React.useState(false);
  const { open: openSelfieDialog, component: selfieDialogComponent } =
    useSelfieDialog({ fullScreen: true });
  const { id: checkInId } = useParams<{ id: string }>();
  const [{ show: showSignWellWindow, url: signWellUrl }, setSignWellState] =
    React.useState({
      show: false,
      url: "",
    });

  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const {
    open: openRoomInspectionDialog,
    component: roomInspectionDialogComponent,
  } = useRoomInspectionWizardDialog({
    onCompleted: async () => {
      try {
        await NiceModal.show(CheckInCompleteModal, {
          onCancel: () => {},
          onProceed: async () => {
            try {
              setIsLoading(true);
              const response: IApiResponse<unknown> = await API.post(
                EXECUTE_REQUEST_PATH,
                {
                  entityName: "CheckIn",
                  requestName: "UpsertRecordReq",
                  recordId: checkInId,
                  inputParamters: {
                    Entity: {
                      CheckInStatusId: 1003,
                      Acknowledgement: 1048,
                    },
                  },
                }
              );
              if (!response.isSuccess) {
                throw new Error("Failed to update check-in status");
              }
              if (isInitialCheckIn) {
                const response: IApiResponse<{ url: string, isInitial :boolean, isVerified :boolean  }> = await API.post(
                  EXECUTE_REQUEST_PATH,
                  {
                    entityName: "CheckIn",
                    requestName: "RetrieveSignwellReq",
                    recordId: checkInId,
                    inputParamters: {
                      UserType: currentUser?.relatedObjectIdObjectTypeCode,
                    },
                  }
                );
                if (response.isSuccess) {

                  if (response.outputParameters.isVerified){

                    if (response.outputParameters.isInitial){
                      setSignWellState({
                      show: true,
                      url: response.outputParameters.url,
                    });
                    }else{
                      window.open(response.outputParameters.url,'_blank');
                      navigate(URLS.STUDENT.CHECK_IN_LISTING);
                    }
                  }else{

                    navigate(URLS.STUDENT.CHECK_IN_LISTING);
                  }

                  
                  
                } else {
                  throw new Error("Failed to retrieve signwell url");
                }
              } else {
                navigate(URLS.STUDENT.CHECK_IN_LISTING);
              }
            } catch(e: any){
              showErrorAlert(e.message);
              navigate(URLS.STUDENT.CHECK_IN_LISTING);
            } finally {
              setIsLoading(false);
            }
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    checkInId,
  });

  const uploadPics = async () => {
    const formValues = methods.getValues();
    await NiceModal.show(UploadPhotosModal, {
      roomName: formValues.roomName,
      numberOfBeds: formValues.NumberOfBeds,
      roomType: formValues.roomType,
      selfie: formValues.selfie,
      roomPicture: formValues.roomBedPic,
      checkInId,
      onComplete: () => openRoomInspectionDialog(),
      onCancel: () => {
        console.log("cancel");
      },
    });
  };

  const takeSelfie = async () => {
    await openSelfieDialog({
      title: "Verify your identity",
      subtitle: "Place your face in the frame below",
      onClose: (selfie) => {
        if (!selfie) {
          return;
        }
        methods.setValue("selfie", selfie);
        NiceModal.show(WizardCheckListModal, {
          isSelfieDone: true,
          onConfirm: () => {
            onNextStep(2);
          },
          onCancel: () => {
            console.log("cancel");
          },
        });
      },
    });
  };

  const takeRoomPic = async () => {
    await openSelfieDialog({
      title: "Verify your identity",
      subtitle: "Take a picture of the bed",
      facingMode: "environment",
      onClose: (pic) => {
        if (!pic) {
          return;
        }
        methods.setValue("roomBedPic", pic);
        NiceModal.show(WizardCheckListModal, {
          isSelfieDone: true,
          isRoomPictureDone: true,
          onConfirm: () => {
            onNextStep(3);
          },
          onCancel: () => {
            console.log("cancel");
          },
        });
      },
    });
  };

  const onNextStep = async (jumpToStep: 1 | 2 | 3) => {
    if (jumpToStep === 3) {
      await uploadPics();
    } else if (jumpToStep === 1) {
      await takeSelfie();
    } else if (jumpToStep === 2) {
      await takeRoomPic();
    }
  };

  return (
    <FormProvider {...methods}>
      <AsyncContent loading={isLoading}>
        <InitialForm onNextStep={() => onNextStep(1)} />
      </AsyncContent>
      {selfieDialogComponent}
      {roomInspectionDialogComponent}
      <SignWellWindow
        show={showSignWellWindow}
        url={signWellUrl}
        onClose={() => {
          setSignWellState({ show: false, url: "" });
          navigate(URLS.STUDENT.CHECK_IN_LISTING);
        }}
      />
    </FormProvider>
  );
}

export default CheckInFlow;
