import { useCallback, useEffect, useState } from "react";
import DashboardPageLayout from "../../../components/DashboardPageLayout";
import ContentPage from "../../../components/DashboardPageLayout/ContentPage";
import { BreadcrumbData } from "../../../components/Breadcrumbs/types";
import { URLS } from "../../../../../_config";
import Grid from "@mui/material/Grid";
import ResponsiveGridSpacer from "../../../components/ResponsiveGridSpacer";
import TextField from "../../../components/TextField";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import EmptyState from "./EmptyState";
import { EXECUTE_REQUEST_PATH, PHONE_NUMBER_REGEX } from "../../../constants";
import ListingGrid from "../../../components/GenericListingScreen/ListingGrid";
import StatusPill from "../../../components/StatusPill";
import { TListingGridProps } from "../../../components/GenericListingScreen/ListingGrid/types";
import { IApiResponse } from "../../../types/IApiResponse";
import { API } from "../../../services/API";
import useCurrentUser from "../../../hooks/useCurrentUser";
import FeatureBlocks from "../../../components/FeatureBlocks";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { ThemeColors } from "../../../styles/theme";
import useFormFieldErrorProps from "../../../hooks/useFormFieldErrorProps";
import { ApUtils } from "../util";
import { TStudentSearchResponse } from "./types";
import CircleLoader from "../../../components/CircleLoader";
import { showErrorAlert, showSuccessAlert } from "../../../components/Notify";
import { useNavigate } from "react-router-dom";

type StudentSearchFormData = {
  searchText: string;
};

type TInviteStudentForm = {
  idNumber: string;
  mobile: string;
};

const BREADCRUMB_DATA: BreadcrumbData = [
  {
    label: "Home",
    path: URLS.HOME,
  },
  {
    label: "Student Search",
    path: URLS.AP.STUDENT_SEARCH,
  },
];

const NoRowsComponent = ({
  onInviteToApplyClick,
  onSearchAnotherClick,
}: {
  onSearchAnotherClick: () => void;
  onInviteToApplyClick: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        py: 8,
      }}
    >
      <FeatureBlocks.Icon icon={<GroupOutlinedIcon />} />
      <Typography sx={{ pt: 2, fontWeight: 600 }}>No student found</Typography>
      <Typography
        sx={{
          pt: 2,
          fontSize: 14,
          color: ThemeColors.textTertiary,
          display: "inline-block",
          maxWidth: 352,
          textAlign: "center",
        }}
        component="div"
      >
        This student does not have a lease history
      </Typography>
      <Box display="inline-flex" columnGap={1} pt={2}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onSearchAnotherClick}
        >
          Search another
        </Button>
        {/* <Button variant="contained" onClick={onInviteToApplyClick}>
          Invite to apply
        </Button> */}
      </Box>
    </Box>
  );
};

const InviteStudentForm = ({
  defaultStudentId,
}: {
  defaultStudentId?: string;
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { isValid, errors, isSubmitting },
  } = useForm<TInviteStudentForm>({
    mode: "all",
    defaultValues: {
      idNumber: defaultStudentId || "",
      mobile: "",
    },
  });

  const { getFieldErrorProps } =
    useFormFieldErrorProps<TInviteStudentForm>(errors);
  const currentUser = useCurrentUser();

  const onSubmit = async (data: TInviteStudentForm) => {
    try {
      const response: IApiResponse<unknown> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "Employee",
          requestName: "InviteStudent",
          inputParamters: {
            accomodationProviderId: currentUser?.supplierId,
            idNumber: data.idNumber,
            mobile: data.mobile,
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(response.clientMessage);
        return;
      }
      showSuccessAlert("Student invited successfully");
    } catch (e) {
      showErrorAlert("An error occurred while inviting the student");
      console.error(e);
    }
  };

  useEffect(() => {
    if (defaultStudentId) {
      reset({ idNumber: defaultStudentId });
    }
  }, [defaultStudentId]);

  return (
    <Paper component="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container>
        <ResponsiveGridSpacer width={4} />
        <Grid item md={4} mb={6}>
          <Typography
            sx={{
              pt: 2,
              fontWeight: 600,
              fontSize: 30,
              my: 1,
              textAlign: "center",
            }}
          >
            Invite Student to Apply
          </Typography>
          <Typography
            sx={{
              pt: 2,
              fontSize: 14,
              color: ThemeColors.textTertiary,
              textAlign: "center",
            }}
            component="div"
          >
            This student does not have a lease history, you can proceed to
            invite them to apply to your property below
          </Typography>
          <Box display="flex" flexDirection="column" rowGap={2} my={2}>
            <TextField
              label="SA ID Number"
              size="small"
              {...getFieldErrorProps("idNumber")}
              {...register("idNumber", { required: true })}
            />
            <TextField
              label="Cellphone"
              size="small"
              {...getFieldErrorProps("mobile")}
              {...register("mobile", {
                required: true,
                pattern: PHONE_NUMBER_REGEX,
              })}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!isValid || isSubmitting}
            color="primary"
            endIcon={isSubmitting ? <CircleLoader /> : undefined}
          >
            Invite to apply
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const COLUMNS: TListingGridProps["columns"] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
  },
  {
    field: "idNumber",
    headerName: "ID Number",
    flex: 1,
  },
  {
    field: "mobile",
    headerName: "Phone Number",
    flex: 1,
    minWidth: 120,
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 180,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 130,
    renderCell: ({ value }) => {
      return (
        value && (
          <StatusPill
            variant={ApUtils.getStudentVerificationStatusStyle(value)}
          >
            {ApUtils.getStudentVerificationStatusLabel(value)}
          </StatusPill>
        )
      );
    },
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    minWidth: 120,
    renderCell: ({ value: Component }) => {
      return Component ? <Component /> : null;
    },
  },
];

const Loader = () => {
  return (
    <Box
      sx={{
        display: "flex",
        py: 8,
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CircularProgress size={48} />
      <Typography sx={{ pt: 2 }}>
        Please wait while we fetch your data.
      </Typography>
    </Box>
  );
};

function StudentSearch() {
  const [searchedText, setSearchedText] = useState<string>("");
  const [pageMode, setPageMode] = useState<"search" | "invite">("search");
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const {
    handleSubmit,
    register,
    reset,
    formState: { isValid },
  } = useForm<StudentSearchFormData>({
    mode: "all",
  });
  const currentUser = useCurrentUser();
  const onSubmit = (data: StudentSearchFormData) => {
    setSearchedText(data.searchText);
  };

  const navigate = useNavigate();

  const dataFetchFn: TListingGridProps["dataFetchFn"] = useCallback(
    async (params) => {
      try {
        const response: IApiResponse<TStudentSearchResponse> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: "Employee",
            requestName: "StudentSearch",
            inputParamters: {
              accomodationProviderId: currentUser?.supplierId,
              SearchText: params.searchedText,
              PageNumber: params.page + 1,
              PageSize: params.pageSize,
              Filters: [],
            },
          }
        );
        if (!response.isSuccess) {
          //throw new Error(response.clientMessage);
          showErrorAlert(response.clientMessage);
        }
        return {
          data: response.outputParameters.Listing.map((entry) => {
            return {
              id: entry.employeeId,
              name: entry.name,
              idNumber: entry.idNumber,
              email: entry.email,
              mobile: entry.mobile,
              status: entry.status,
              actions: () => (
                <Button
                  size="small"
                  variant="outlined"
                  onClick={
                    () =>
                      navigate(
                        `/ap/students/${entry.employeeId}?numbers=${entry.mobile}`
                      )
                    // navigate(
                    //   `/ap/students/${entry.idNumber}/security?number=${entry.mobile}&email=${entry.email}&&employee-id=${entry.employeeId}`
                    // )
                  }
                >
                  View
                </Button>
              ),
            };
          }),
          totalCount: response.outputParameters.RecordCount,
          hasError: false,
        };
      } catch (e) {
        console.error(e);
        return {
          hasError: true,
          data: [],
        };
      }
    },
    [searchedText]
  );

  return (
    <DashboardPageLayout background="primary">
      <ContentPage
        title="Student Search"
        subtitle="Find a student by ID number"
        padding="lg"
        breadcrumbData={BREADCRUMB_DATA}
      >
        {pageMode === "search" && (
          <Grid container component="form" onSubmit={handleSubmit(onSubmit)}>
            <ResponsiveGridSpacer width={1} />
            <Grid item container xs={12} md={10} display="flex">
              <Grid item xs={12} md={9} sx={{ pr: { xs: 0, md: 1 }, pt: 1 }}>
                <TextField
                  placeholder="Search for a student"
                  {...register("searchText", { required: true })}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={3}
                display="flex"
                alignItems="stretch"
                pt={1}
              >
                <Button
                  type="submit"
                  disabled={!isValid}
                  variant="contained"
                  fullWidth
                  color="primary"
                  endIcon={<Search />}
                >
                  Search Students
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
                {loading && <Loader />}
                {!searchedText && isEmpty && <EmptyState />}
                {searchedText && (
                  <Box pt={4}>
                    <ListingGrid
                      title="Search Results"
                      columns={COLUMNS}
                      dataFetchFn={dataFetchFn}
                      searchedText={searchedText}
                      activeFilters={[]}
                      hideActionButtons
                      noResultComponent={
                        <NoRowsComponent
                          onInviteToApplyClick={() => setPageMode("invite")}
                          // onInviteToApplyClick={() =>
                          //   navigate(
                          //     "/ap/students/search/0408084834188/security"
                          //   )
                          // }
                          onSearchAnotherClick={() => {
                            setSearchedText("");
                            reset({ searchText: "" });
                          }}
                        />
                      }
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* {pageMode === "invite" && (
          <Grid container>
            <ResponsiveGridSpacer width={1} />
            <Grid item xs={12} md={10}>
              <InviteStudentForm defaultStudentId={searchedText} />
            </Grid>
          </Grid>
        )} */}
      </ContentPage>
    </DashboardPageLayout>
  );
}

export default StudentSearch;
