import NiceModal, { useModal } from "@ebay/nice-modal-react";
import {
  AppBar,
  Box,
  DialogContentText,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgressWithLabel from "../../../components/CircularProgressWithLabel";
import { useEffect, useRef, useState } from "react";
import { IApiResponse } from "../../../types/IApiResponse";
import { EMPTY_OBJECT, EXECUTE_REQUEST_PATH } from "../../../constants";
import { DocumentType } from "../../../types/shared";
import { UTILS } from "../../../../../utils";
import { API } from "../../../services/API";
import Close from "@mui/icons-material/Close";
export default NiceModal.create(
  ({
    roomName,
    numberOfBeds,
    roomType,
    selfie,
    roomPicture,
    checkInId,
    onComplete,
    onCancel,
  }: {
    nextStep: "lease" | "home";
    checkInId: string;
    roomName: string;
    numberOfBeds: string;
    roomType: string;
    selfie: string;
    roomPicture: string;
    onCancel?: () => void;
    onComplete: () => void;
  }) => {
    // Use a hook to manage the modal state
    const modal = useModal();

    const handleCancel = async (e: unknown, reason?: string) => {
      if (reason && reason === "backdropClick") {
        return;
      }
      onCancel?.();
      await modal.hide();
      modal.remove();
    };

    const [progress, setProgress] = useState(14);

    const [uploadState, setUploadState] = useState<{
      state: "idle" | "uploading" | "success";
    }>({ state: "idle" });

    const progressTimerRef = useRef<any>();

    const uploadFiles = async () => {
      try {
        setUploadState({ state: "uploading" });
        setProgress(14);
        const response: IApiResponse<unknown> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: "CheckIn",
            requestName: "UpsertRecordReq",
            recordId: checkInId,
            inputParamters: {
              Entity: {
                CheckInDate: new Date().toISOString(),
                RoomNumber: roomName,
                CapacityId: roomType,
                RoomSize: numberOfBeds,
              },
              Documents: [
                {
                  FileName: "selfie",
                  FileExtention: "jpg",
                  DocumentTypeId: DocumentType.selfie,
                  FileContent: UTILS.extractBase64FromDataUrl(selfie),
                },
                {
                  FileName: "roomPicture",
                  FileExtention: "jpg",
                  DocumentTypeId: DocumentType.roomPicture,
                  FileContent: UTILS.extractBase64FromDataUrl(roomPicture),
                },
              ],
            },
          }
        );
        if (response.isSuccess) {
          setProgress(95);
          setTimeout(async () => {
            setUploadState({ state: "success" });
            onComplete();
            await modal.hide();
            modal.remove();
          }, 1000);
        } else {
          throw new Error("An error occurred while uploading");
        }
      } catch (e) {
        console.error(e);
        setUploadState({ state: "idle" });
      }
    };

    useEffect(() => {
      uploadFiles();
    }, [selfie]);

    useEffect(() => {
      if (uploadState.state === "uploading") {
        progressTimerRef.current = setInterval(() => {
          setProgress((prevProgress) =>
            prevProgress >= 97 ? 97 : prevProgress + 1
          );
        }, 800);
        return () => {
          clearInterval(progressTimerRef.current);
        };
      } else if (uploadState.state === "success") {
        setProgress(97);
        if (progressTimerRef.current) {
          clearInterval(progressTimerRef.current);
        }
      }
      return () => {
        clearInterval(progressTimerRef.current);
      };
    }, [uploadState.state]);

    if (!modal.visible) return null;
    return (
      <Dialog fullScreen open onClose={handleCancel}>
        <AppBar sx={{ position: "relative" }} elevation={0} color="transparent">
          <Toolbar>
            {uploadState.state !== "uploading" && (
              <IconButton
                edge="start"
                onClick={handleCancel}
                aria-label="close"
              >
                <Close />
              </IconButton>
            )}
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              &nbsp;
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={
            uploadState.state === "uploading"
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : EMPTY_OBJECT
          }
        >
          {uploadState.state === "uploading" && (
            <DialogContentText>
              <Box sx={{ height: "100%", width: "100%" }}>
                <CircularProgressWithLabel value={progress} size={90} />
              </Box>
            </DialogContentText>
          )}
        </DialogContent>
      </Dialog>
    );
  }
);
