import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCommonApi } from "../_common/hooks/api/common/commonApiHook";
import { useForm } from "react-hook-form";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import toast from "react-hot-toast";
import { API } from "../ui/v2/services/API";
import { IApiResponse } from "../ui/v2/types/IApiResponse";
import { showErrorAlert } from "../components/Notify";
import {
  EXECUTE_REQUEST_PATH,
  GET_AP_SIGNING_URL_PATH,
} from "../ui/v2/constants";
import useFormFieldErrorProps from "../ui/v2/hooks/useFormFieldErrorProps";
import MonthPeriodSelect from "../ui/v2/components/MonthPeriodSelect";
import TextField from "../ui/v2/components/TextField";
import Select from "../ui/v2/components/Select";
import { format } from "date-fns";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "@mui/material";
import useCurrentUser from "../ui/v2/hooks/useCurrentUser";
import DateMonthPeriodSelect from "../ui/v2/components/DateMonthPeriodSelector";

interface ApproveModalProps {
  applicationId: string;
  facilityId: string;
  plannedMoveInDate?: Date;
  onApprove: (signWellUrl: string) => void;
  onClose: () => void;
}
type FormSchema = { CapacityId: string; RoomNumber: string; StartDate: string };

const ApproveModal: React.FC<ApproveModalProps> = ({
  onClose,
  onApprove,
  applicationId,
  plannedMoveInDate,
  facilityId,
}) => {
  const commonApi = useCommonApi();
  const { showLoader, hideLoader } = useAppLoader();

  function getFirstDayOfMonth() {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  }

  console.log("planned", plannedMoveInDate);

  const defaultDate =
    plannedMoveInDate?.toString() === "Invalid date"
      ? getFirstDayOfMonth()
      : plannedMoveInDate;

  console.log("defaultDate", defaultDate);

  function getFirstDayOfMonth1() {
    const today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), 1);
  }

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormSchema>({
    mode: "onChange",
  });

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    setValue("StartDate", `${date}`); // Update the form value
  };

  const { getFieldErrorProps } = useFormFieldErrorProps<FormSchema>(errors);

  const [roomTypes, setRoomTypes] = useState([]);
  const [startDate, setStartDate] = useState<any>(null);

  const { id } = useParams();

  const getApplicationRoomTypes = () => {
    commonApi.getApplicationRoomTypes(
      facilityId
        ? {
            FacilityId: facilityId || id,
          }
        : {
            AccomodationApplicationsId: applicationId,
          },
      (message: string, resp: any) => {
        let opt: any = [];
        if (resp.length > 0) {
          resp.map((o: any) => {
            if (o.CapacityId.search("000000") !== 0) {
              o.label = o.AmenityIdName;
              o.value = o.CapacityId;
              opt.push(o);
            }
          });

          setRoomTypes(opt);
        }
      },
      () => {}
    );
  };

  const approveApplication = async (data: FormSchema) => {
    try {
      showLoader();
      const response: IApiResponse<ResponseType> = await API.post(
        EXECUTE_REQUEST_PATH,
        {
          entityName: "AccomodationApplications",
          requestName: "UpsertRecordReq",
          recordId: applicationId,
          inputParamters: {
            Entity: {
              StatusId: 69,
              CapacityId: data.CapacityId,
              startDate: data.StartDate,
              RoomNumber: data.RoomNumber,
            },
          },
        }
      );
      if (!response.isSuccess) {
        showErrorAlert(
          response.clientMessage || "Failed to approve application"
        );
      }
      // get the sign well
      const signWellResponse: {
        TenantId: string;
        Name: string;
        APSigningURL: string;
      }[] = await API.get(
        `${GET_AP_SIGNING_URL_PATH}?ApplicationId=${applicationId}`
      );
      if (signWellResponse[0]?.APSigningURL) {
        onApprove(signWellResponse[0].APSigningURL);

        toast.success("Successfully approved. You may now sign lease", {
          duration: 2000,
        });

        console.log(
          "signWellResponse[0].APSigningURL",
          signWellResponse[0].APSigningURL
        );
      } else {
        onClose();
      }
    } catch (e: any) {
      console.error(e);
      toast.error(e.message);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getApplicationRoomTypes();
  }, []);

  return (
    <>
      <div>
        <h6>
          Provide the accommodation details to be provided to the student prior
          to moving in.
        </h6>
        <div className="">
          <form onSubmit={handleSubmit(approveApplication)}>
            <div className="tab_scrollcontent">
              {defaultDate && (
                <div className="mb-2">
                  <TextField
                    label="Planned Move In Date"
                    size="small"
                    value={format(defaultDate, "dd/MM/yyyy")}
                    disabled
                  />
                </div>
              )}
              <div className="mb-2">
                <DateMonthPeriodSelect
                  mode="start"
                  label="Start Date"
                  selectedDate={startDate}
                  minDate={plannedMoveInDate}
                  {...register("StartDate", { required: true })}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="mb-2">
                <TextField
                  {...register("RoomNumber", { required: true, maxLength: 80 })}
                  {...getFieldErrorProps("RoomNumber")}
                  size="small"
                  label="Select a Room Number"
                />
              </div>
              <div className="mb-4">
                <Select
                  {...register("CapacityId", { required: true })}
                  label="Select a Room Type"
                  size="small"
                  options={roomTypes}
                  {...getFieldErrorProps("CapacityId")}
                />
              </div>
            </div>
            <div>
              <Button fullWidth variant="contained" type="submit">
                Confirm
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const NiceApproveModal = NiceModal.create((props: ApproveModalProps) => {
  const modal = useModal();
  const handleClose = () => {
    props?.onClose();
    modal.hide().finally(() => {
      modal.remove();
    });
  };

  const handleApprove = (signWellUrl: string) => {
    props?.onApprove(signWellUrl);
    modal.hide().finally(() => {
      modal.remove();
    });
  };

  if (modal.visible) {
    return (
      <Dialog open={true} onClose={handleClose}>
        <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ flex: 1, textAlign: "center" }}>Approve</Box>
          <Box>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <ApproveModal
            {...props}
            onClose={handleClose}
            onApprove={handleApprove}
          />
        </DialogContent>
      </Dialog>
    );
  }
  return null;
});

export default ApproveModal;
