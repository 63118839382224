import React, { useEffect, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import FormGridItem from "../ui/v2/components/FormGridItem";
import TextField from "../ui/v2/components/TextField";

import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Close } from "@mui/icons-material";
import DescriptionIcon from "@mui/icons-material/Description";
import useCurrentUser from "../ui/v2/hooks/useCurrentUser";
import { Controller, useForm } from "react-hook-form";
import {
  UPLOADS_ACCEPTED_FILE_TYPES,
  EMPTY_ARRAY,
  EMPTY_GUID,
  EXECUTE_REQUEST_PATH,
} from "../ui/v2/constants";
import { API } from "../ui/v2/services/API";
import TextArea from "../ui/v2/components/TextArea";
import { UTILS } from "../utils";
import FileDropzone from "../ui/v2/components/FileDropzone";
import Button from "@mui/material/Button";
import CircleLoader from "../ui/v2/components/CircleLoader";
import { showErrorAlert, showSuccessAlert } from "../components/Notify";
import Select from "../ui/v2/components/Select";
import { IApiResponse } from "../ui/v2/types/IApiResponse";
import { useLocation, useNavigate } from "react-router-dom";
import { URLS } from "../_config";
import Header from "./student/components/header";
import { TNewDocumentUpload } from "../ui/v2/types/TDocumentUpload";
import { Autocomplete } from "@mui/material";

type TBaseClassification = {
  caseClassificationId: string;
  name: string;
  isTermination: boolean;
};

type TCreateCaseResponse = {
  UpsertResponse: {
    recordId: string;
    record: string | null;
    success: boolean;
    errorMesssage: string | null;
    upsertAction: number;
  };
};
type TSubClassification = TBaseClassification;

type TParentClassification = TBaseClassification & {
  isParent: true;
  subClassifications: TSubClassification[];
};

type TNonParentClassification = TBaseClassification & {
  isParent: false;
  subClassifications: null;
};

type TCaseClassificationResponseType = {
  CaseClassification: (TParentClassification | TNonParentClassification)[];
};

type NoticeMonth = {
  key: string;
  value: number;
};

type TTermDates = {
  NoticeYear: number;
  NoticeMonths: NoticeMonth[];
};

type TRegardingOption = {
  name: string;
  recordId: string;
  objectTypeCode: string;
};

type TRegardingOptions = TRegardingOption[];

type TRegardingOptionsResponse = {
  RegardingDetails: TRegardingOption[];
};

const emptySubquery: {
  caseSubClassifications: TSubClassification[];
  otherSubqueryId: string | undefined;
} = {
  caseSubClassifications: [],
  otherSubqueryId: undefined,
};

function CreateCase_TEMPORARY() {
  const currentUser = useCurrentUser();

  console.log("currentUser", currentUser);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isLoadingCaseClassifications, setIsLoadingCaseClassifications] =
    React.useState(true);
  const [isLoadingRegardingOptions, setIsLoadingRegardingOptions] =
    useState(false);
  const [terminationMonths, setTerminationMonths] = useState<
    { label: string; value: string }[]
  >([]);
  const { handleSubmit, register, watch, setValue, control } = useForm();

  const isLoading = !currentUser || isLoadingCaseClassifications;
  const [invoiceDisputeGuid, setInvoiceDisputeGuid] =
    React.useState<string>("");
  const [regardingOptions, setRegardingOptions] =
    React.useState<TRegardingOptions>([]);

  const [caseClassifications, setCaseClassifications] = React.useState<
    TCaseClassificationResponseType["CaseClassification"]
  >([]);

  const [otherCaseClassificationGuid, setOtherCaseClassificationGuid] =
    useState<string>();
  const [
    leaseTerminationCaseClassificationGuid,
    setLeaseTerminationCaseCaseClassificationGuid,
  ] = useState<string>();

  useEffect(() => {
    const fetchTermDates = async () => {
      try {
        setIsLoadingCaseClassifications(true);
        if (!currentUser) {
          return;
        }

        const inputParamters = currentUser.supplierId
          ? {
              UserId: currentUser?.supplierId || currentUser.recordId,
              UserType: "Supplier",
            }
          : {
              UserId: currentUser?.supplierId || currentUser.recordId,
              UserType: "Employee",
            };

        const response: IApiResponse<TTermDates> = await API.post(
          EXECUTE_REQUEST_PATH,
          {
            entityName: "Term",
            requestName: "RetrieveTermDates",
            inputParamters: inputParamters,
          }
        );
        if (!response.isSuccess) {
          showErrorAlert(response.clientMessage || "Error fetching Term Dates");
        }

        let noticeYear = response.outputParameters.NoticeYear;

        if (response.outputParameters.NoticeYear === 962) {
          noticeYear = 2024;
        }
        if (response.outputParameters.NoticeYear === 963) {
          noticeYear = 2025;
        }
        if (response.outputParameters.NoticeYear === 964) {
          noticeYear = 2026;
        }

        const terminationMonths = response.outputParameters.NoticeMonths.map(
          (monthObj) => {
            const label = monthObj.key;
            const value = monthObj.value;

            return { label: `${label + " " + noticeYear}`, value: `${value}` };
          }
        );

        setTerminationMonths(terminationMonths);
        setValue("NoticeYear", noticeYear);
      } catch (e) {
        showErrorAlert("Error fetching case classifications");
        setIsLoadingCaseClassifications(false);
      } finally {
        setIsLoadingCaseClassifications(false);
      }
    };

    const fetchCaseClassifications = async () => {
      try {
        setIsLoadingCaseClassifications(true);
        if (!currentUser) {
          return;
        }
        const response: IApiResponse<TCaseClassificationResponseType> =
          await API.post(EXECUTE_REQUEST_PATH, {
            entityName: "Cases",
            requestName: "RetrieveCaseClassifications",
            inputParamters: {
              UserType: currentUser?.supplierId ? "Supplier" : "Employee",
              UserId: currentUser?.supplierId || currentUser.recordId,
            },
          });
        if (!response.isSuccess) {
          showErrorAlert(
            response.clientMessage || "Error fetching case classifications"
          );
        }

        setCaseClassifications(response.outputParameters.CaseClassification);
        setOtherCaseClassificationGuid((current) => {
          const otherClassification =
            response.outputParameters.CaseClassification.find(
              (el) => el.name === "Other"
            );
          return otherClassification?.caseClassificationId;
        });
        setLeaseTerminationCaseCaseClassificationGuid((current) => {
          const otherClassification =
            response.outputParameters.CaseClassification.find(
              (el) => el.isTermination === true
            );
          return otherClassification?.caseClassificationId;
        });
      } catch (e) {
        showErrorAlert("Error fetching case classifications");
        setIsLoadingCaseClassifications(false);
      } finally {
        setIsLoadingCaseClassifications(false);
      }
    };
    fetchCaseClassifications();

    fetchTermDates();
  }, [currentUser]);

  const CaseClassificationId = watch("CaseClassificationId");
  const RegardingId = watch("regardingId");

  const { caseSubClassifications, otherSubqueryId } = useMemo(() => {
    if (!CaseClassificationId) {
      return emptySubquery;
    }
    const selectedCase = caseClassifications.find(
      (el) => el.caseClassificationId === CaseClassificationId
    );
    if (!selectedCase) {
      return emptySubquery;
    }
    if (selectedCase.isParent) {
      const otherSubqueryId = selectedCase.subClassifications.find(
        (el) => el.name === "Other"
      )?.caseClassificationId;
      return {
        caseSubClassifications: selectedCase.subClassifications,
        otherSubqueryId: otherSubqueryId,
      };
    }
    return emptySubquery;
  }, [CaseClassificationId]);

  const subClassificationId = watch("SubClassificationId");

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    const selectedCase = caseClassifications.find(
      (el) => el.caseClassificationId === CaseClassificationId
    );
    const getRegardingOptions = async () => {
      try {
        setIsLoadingRegardingOptions(true);

        const response: IApiResponse<TRegardingOptionsResponse> =
          await API.post(EXECUTE_REQUEST_PATH, {
            entityName: "Cases",
            requestName: "RetrieveCaseRegardingReq",
            inputParamters: {
              RegardingType: selectedCase?.name,
              UserId: currentUser?.supplierId || currentUser.recordId,
              UserType: currentUser.relatedObjectIdObjectTypeCode,
            },
          });

        if (!response.isSuccess) {
          showErrorAlert(response.clientMessage).then(() =>
            navigate(URLS.CASES)
          );
        }
        console.log("response", response);
        // setRegardingOptions(response.outputParameters.RegardingDetails);
        setValue(
          "regardingId",
          response.outputParameters.RegardingDetails[0].name
        );
        setValue(
          "regardingIdValue",
          response.outputParameters.RegardingDetails[0].recordId
        );
        setValue(
          "objectTypeCode",
          response.outputParameters.RegardingDetails[0].objectTypeCode
        );
      } catch (e) {
        console.error("error", e);
      } finally {
        setIsLoadingRegardingOptions(false);
      }
    };
    getRegardingOptions();
  }, [CaseClassificationId, currentUser]);

  const location = useLocation();

  const [isPrepopulated, setIsPrepopulated] = useState(false);

  useEffect(() => {
    if (!location.state) {
      return;
    }
    if (location.state.invoiceId) {
      // pre populate as Invoice Dispute
      if (caseClassifications.length === 0) {
        console.debug("case classifications not loaded yet");
        return;
      }
      const invoiceDisputeCaseClassification = caseClassifications.find(
        (el) => el.name === "Invoice Dispute"
      )?.caseClassificationId;
      if (!invoiceDisputeCaseClassification) {
        console.debug("unable to locate invoice dispute case classification");
      }
      setValue("CaseClassificationId", invoiceDisputeCaseClassification);
      setTimeout(() => setValue("regardingId", location.state.invoiceId), 0);
    } else if (
      location.state.tenantId &&
      location.state.type === "Termination of lease"
    ) {
      // pre populate for Termination of lease
      if (caseClassifications.length === 0) {
        return;
      }
      const terminationClassification = caseClassifications.find(
        (el) => el.name === "Termination of lease"
      )?.caseClassificationId;
      if (!terminationClassification) {
        console.debug("unable to locate invoice dispute case classification");
      }
      setValue("CaseClassificationId", terminationClassification);
      setTimeout(() => setValue("regardingId", location.state.tenantId), 0);
    }
  }, [location.state, caseClassifications]);

  const navigate = useNavigate();

  const onSubmit = async (data: Record<string, string>) => {
    const {
      Description,
      CaseClassificationId,
      Subject,
      NoticeMonth,
      NoticeYear,
      SubClassificationId,
      regardingId,
      SubClassificationOtherInput,
      ClassificationOtherInput,
      regardingIdValue,
      objectTypeCode,
    } = data;

    try {
      if (!currentUser) {
        return;
      }
      setIsSubmitting(true);

      const caseCreatedResponse: IApiResponse<TCreateCaseResponse> =
        await API.post(EXECUTE_REQUEST_PATH, {
          entityName: "Cases",
          requestName: "UpsertRecordReq",
          inputParamters: {
            Entity: {
              CaseClassificationId,
              Subject,
              Description,
              NoticeMonth: Number(NoticeMonth), //WARN termination date added
              NoticeYear: Number(NoticeYear), //WARN termination date added
              regardingId: regardingIdValue,
              regardingIdObjectTypeCode: objectTypeCode,
              CustomerId: currentUser?.supplierId || currentUser.recordId,
              CustomerIdObjectTypeCode:
                currentUser?.relatedObjectIdObjectTypeCode || "Employee",
              ChannelId: "681",
              CasesStatusId: "307",
              SubClassificationId: SubClassificationId,
              OtherReason:
                ClassificationOtherInput ?? SubClassificationOtherInput,
            },
            Documents: files,
          },
        });

      if (caseCreatedResponse.isSuccess)
        showSuccessAlert(caseCreatedResponse.clientMessage).then(() =>
          navigate(URLS.CASES)
        );
      if (!caseCreatedResponse.isSuccess)
        showSuccessAlert("Something went wrong!").then(() =>
          navigate(URLS.CASES)
        );
    } catch (e) {
      console.error("🔴 error", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const [files, setFiles] = React.useState<TNewDocumentUpload<697>[]>([]);

  return (
    <>
      <Header />
      <Box sx={{ pb: 12, pt: 8, px: { md: 10, xs: 2 } }}>
        <Grid
          component="form"
          onSubmit={handleSubmit(onSubmit, (e) => console.warn(e))}
          container
          pt={2}
        >
          <FormGridItem xs={12} md={12}>
            <Typography variant="h4">Create Case</Typography>
          </FormGridItem>
          <FormGridItem>
            <TextField
              label="Name"
              disabled
              size="small"
              placeholder={currentUser?.name}
            />
          </FormGridItem>
          <FormGridItem>
            <TextField
              label="Email"
              disabled
              size="small"
              placeholder={currentUser?.email}
            />
          </FormGridItem>
          <FormGridItem xs={12} md={12}>
            <Select
              disabled={isPrepopulated}
              {...register("CaseClassificationId", {
                required: "This field is required",
              })}
              label="Query"
              options={caseClassifications
                .filter((el) => el.caseClassificationId !== EMPTY_GUID)
                .map((el) => ({
                  label: el.name,
                  value: el.caseClassificationId,
                }))}
              size="small"
              placeholder="Select query"
            />
          </FormGridItem>
          {CaseClassificationId === leaseTerminationCaseClassificationGuid && (
            <FormGridItem xs={12} md={12}>
              <Select
                disabled={isPrepopulated}
                {...register("NoticeMonth")}
                label="Notice Month"
                options={terminationMonths.map(
                  (el: { value: string; label: string }) => ({
                    label: el.label,
                    value: el.value,
                  })
                )}
                size="small"
                placeholder="Select date"
              />
            </FormGridItem>
          )}
          {/* {CaseClassificationId === leaseTerminationCaseClassificationGuid && (
            <FormGridItem xs={12} md={12}>
              <TextField
                label="Notice year"
                placeholder="Lease Termination Notice year"
                size="small"
                disabled
                fullWidth
                {...register("NoticeYear", {
                  shouldUnregister: true,
                })}
              />
            </FormGridItem>
          )} */}

          {CaseClassificationId === otherCaseClassificationGuid && (
            <FormGridItem xs={12} md={12}>
              <TextField
                label="Specify other query"
                size="small"
                fullWidth
                {...register("ClassificationOtherInput", {
                  shouldUnregister: true,
                })}
              />
            </FormGridItem>
          )}

          {caseSubClassifications.length > 0 && (
            <FormGridItem xs={12} md={12}>
              <Select
                disabled={isPrepopulated}
                {...register("SubClassificationId", {
                  required: "This field is required",
                })}
                label="Sub-Query"
                options={caseSubClassifications
                  .filter((el) => el.caseClassificationId !== EMPTY_GUID)
                  .map((el) => ({
                    label: el.name,
                    value: el.caseClassificationId,
                  }))}
                size="small"
                placeholder="Select sub-query"
              />
            </FormGridItem>
          )}
          {caseSubClassifications.length > 0 &&
            subClassificationId === otherSubqueryId && (
              <FormGridItem xs={12} md={12}>
                <TextField
                  label="Specify other sub-classification"
                  size="small"
                  fullWidth
                  {...register("SubClassificationOtherInput", {
                    shouldUnregister: true,
                  })}
                />
              </FormGridItem>
            )}

          <FormGridItem xs={12} md={12}>
            <TextField
              label="Regarding"
              size="small"
              fullWidth
              {...register("regardingId", {
                shouldUnregister: true,
              })}
              disabled
            />
          </FormGridItem>

          <FormGridItem md={12}>
            <TextField
              label="Subject"
              {...register("Subject", { required: "This field is required" })}
              size="small"
              placeholder="Enter your subject"
            />
          </FormGridItem>
          <FormGridItem md={12}>
            <TextArea
              {...register("Description", {
                required: "This field is required",
              })}
            />
          </FormGridItem>
          <FormGridItem md={12}>
            {files.map(({ id, FileName }) => (
              <Box
                key={id}
                sx={{
                  display: "inline-flex",
                  maxWidth: 300,
                  alignItems: "center",
                  justifyContent: "space-between",
                  mr: 1,
                  mb: 1,
                  px: 1,
                  border: "1px solid #aaa",
                  backgroundColor: "#f5f5f5",
                  borderRadius: 2,
                }}
              >
                <DescriptionIcon color="info" />
                <Typography noWrap sx={{ fontSize: 14 }}>
                  {FileName}
                </Typography>
                <IconButton
                  color="error"
                  onClick={() => {
                    setFiles((current) => current.filter((el) => el.id !== id));
                  }}
                >
                  <Close />
                </IconButton>
              </Box>
            ))}
          </FormGridItem>
          <FormGridItem md={7}>
            <FileDropzone
              acceptedFiles={{ "plain/text": UPLOADS_ACCEPTED_FILE_TYPES }}
              onDrop={(validFiles) => {
                const b64filesPromises = validFiles.map(async (file) => {
                  return new Promise<TNewDocumentUpload<697>>((resolve) => {
                    UTILS.convertFileToBase64(file).then((b64) => {
                      resolve({
                        id: UTILS.getId().toString(),
                        FileName: file.name,
                        FileExtention: file.name.split(".").pop() || "",
                        DocumentTypeId: 697,
                        FileContent: b64.split("base64,")[1],
                      });
                    });
                  });
                });
                Promise.all(b64filesPromises).then((b64files) => {
                  setFiles((current) => [...current, ...b64files]);
                });
              }}
            />
          </FormGridItem>
          <FormGridItem>
            <Button
              variant="contained"
              fullWidth
              type="submit"
              disabled={isSubmitting}
              startIcon={isSubmitting && <CircleLoader />}
            >
              Submit
            </Button>
          </FormGridItem>
        </Grid>
      </Box>
    </>
  );
}

export default CreateCase_TEMPORARY;
